import domain from "@/environment";
import axios from "axios";

const state = {
  certificatRge: null,
  certificatRges: [],
  certificatRgeLoading: false,
  certificatRgeRows: 0,
};

const getters = {
  getCertificatRge: (state) => state.certificatRge,
  getAllCertificatRges: (state) => state.certificatRges,
  getLoadingCertificatRge: (state) => state.certificatRgeLoading,
  getTotalRowCertificatRge: (state) => state.certificatRgeRows,
};

const mutations = {
  SET_LOADING_CERTIFICAT_RGE(state, payload) {
    state.certificatRgeLoading = payload;
  },
  SET_CERTIFICAT_RGE(state, payload) {
    state.certificatRge = payload;
  },
  PUSH_CERTIFICAT_RGE(state, payload) {
    state.certificatRges.push(payload);
  },
  UPDATE_CERTIFICAT_RGE(state, payload) {
    const index = state.certificatRges.findIndex(item => item.id === payload.id);
    if (index !== -1) {
      state.certificatRges.splice(index, 1, payload);
    }
  },
  DELETE_CERTIFICAT_RGE(state, payload) {
    state.certificatRges = state.certificatRges.filter(item => item.id !== payload.id);
  },
  SET_CERTIFICAT_RGES(state, payload) {
   
    if (Array.isArray(payload)) {
      state.certificatRges = payload;
    } else {
      state.certificatRges = [];
    }
  },  
  UPDATE_CERTIFICAT_RGE_ROW(state, payload) {
    state.certificatRgeRows = payload;
  },
};

const actions = {
  async allCertificatRge({ commit }, payload) {
    commit("SET_LOADING_CERTIFICAT_RGE", true);
      const params = {
         page: payload.page || 1,
        per_page: payload.per_page || 10,
        search: payload.search || "",
        sous_traitant_id: payload.sous_traitant_id || null,
        entreprise_id: payload.entreprise_id || null,
      };
  
    try {
      const response = await axios.get(domain + "/certificat_rge/", {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
        params,
      });
  
  
      commit("UPDATE_CERTIFICAT_RGE_ROW", response.data.total);
  
      const data = response.data.data;
      if (Array.isArray(data)) {
        commit("SET_CERTIFICAT_RGES", data);
      } else {
        commit("SET_CERTIFICAT_RGES", []); 
      }
  
      commit("SET_LOADING_CERTIFICAT_RGE", false);
      return true;
    } catch (error) {
      commit("SET_LOADING_CERTIFICAT_RGE", false);
      throw error.response.data.message;
    }
  },  
  async getCertificatRgeById({ commit }, id) {
    commit("SET_LOADING_CERTIFICAT_RGE", true);
    try {
      const response = await axios.get(domain + `/certificat_rge/${id}`, {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
      });
      commit("SET_CERTIFICAT_RGE", response.data.data);
      commit("SET_LOADING_CERTIFICAT_RGE", false);
      return response.data;
    } catch (error) {
      commit("SET_LOADING_CERTIFICAT_RGE", false);
      throw error.response.data.message;
    }
  },

  async storeCertificatRge({ commit }, payload) {
    commit("SET_LOADING_CERTIFICAT_RGE", true);
   
    try {
      const params = {
        page: payload.page,
        per_page: payload.per_page,
        search: payload.search,
        sous_traitant_id: payload.sous_traitant_id,
        entreprise_id: payload.entreprise_id,
        num: payload.num,
        date_attribution: payload.date_attribution,
        date_expiration: payload.date_expiration, 
      };
      const response = await axios.post(domain + `/certificat_rge`, payload, {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
        params
      });
      commit("PUSH_CERTIFICAT_RGE", response.data.data);
      commit("UPDATE_CERTIFICAT_RGE_ROW", state.certificatRgeRows + 1);
      commit("SET_LOADING_CERTIFICAT_RGE", false);
      return true;
    } catch (error) {
      commit("SET_LOADING_CERTIFICAT_RGE", false);
      throw error.response.data.message;
    }
  },

  async updateCertificatRge({ commit }, payload) {
    commit("SET_LOADING_CERTIFICAT_RGE", true);
    try {
      const response = await axios.put(domain + `/certificat_rge/${payload.id}`, payload, {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
      });
      commit("UPDATE_CERTIFICAT_RGE", response.data.data);
      commit("SET_LOADING_CERTIFICAT_RGE", false);
      return response.data;
    } catch (error) {
      commit("SET_LOADING_CERTIFICAT_RGE", false);
      throw error.response.data.message;
    }
  },

  async deleteCertificatRge({ commit }, id) {
    commit("SET_LOADING_CERTIFICAT_RGE", true);
    try {
      await axios.delete(domain + `/certificat_rge/${id}`, {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
      });
      commit("DELETE_CERTIFICAT_RGE", { id });
      commit("UPDATE_CERTIFICAT_RGE_ROW", state.certificatRgeRows - 1);
      commit("SET_LOADING_CERTIFICAT_RGE", false);
      return true;
    } catch (error) {
      commit("SET_LOADING_CERTIFICAT_RGE", false);
      throw error.response.data.message;
    }
  },
};

export default { state, getters, mutations, actions };
