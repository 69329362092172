import domain from "@/environment";
import axios from "axios";

const state = {
  entiteExterne: null,
  entitesExternes: [],
  entiteExterneLoading: false,
  entiteExterneRows: 0,
};

const getters = {
  getEntiteExterne: (state) => state.entiteExterne,
  getAllEntitesExternes: (state) => state.entitesExternes,
  getLoadingEntiteExterne: (state) => state.entiteExterneLoading,
  getTotalRowEntiteExterne: (state) => state.entiteExterneRows,
  getPartenaireEntitesExternes: (state) => {
   
    return state.entitesExternes.filter(
      (entite) => entite.type.toLowerCase() === 'partenaire'
    );
  },

  getMondataireEntitesExternes: (state) => {
    
    return state.entitesExternes.filter(
      (entite) => entite.type.toLowerCase() === 'mandataire'
    );
  },
};

const mutations = {
  DO_NOTHING() {},
  SET_LOADING_ENTITE_EXTERNE(state, payload) {
    state.entiteExterneLoading = payload ? payload : false;
  },
  SET_ENTITE_EXTERNE(state, payload) {
    state.entiteExterne = payload ? payload : null;
  },
  PUSH_ENTITE_EXTERNE(state, payload) {
    state.entitesExternes.push(payload);
  },
  UPDATE_ENTITE_EXTERNE(state, payload) {
    for (let index = 0; index < state.entitesExternes.length; index++) {
      if (state.entitesExternes[index].id === payload.id) {
        state.entitesExternes.splice(index, 1, payload);
        break;
      }
    }
  },
  DELETE_ENTITE_EXTERNE(state, payload) {
    state.entitesExternes = state.entitesExternes.filter(
      (item) => item.id !== payload.id
    );
  },

  UPDATE_ENTITE_EXTERNE_ROW(state, payload) {
    state.entiteExterneRows = payload ? payload : 0;
  },
  SET_ENTITES_EXTERNES(state, payload) {
  
    state.entitesExternes = payload ? payload : [];
  },
  
};

const actions = {
  async allEntitesExternes({ commit }, payload) {
    commit("SET_LOADING_ENTITE_EXTERNE", true);
    const params = {
      page: payload.page,
      per_page: payload.per_page,
      search: payload.search,
      type: payload.type,
    };
    try {
      const response = await axios.get(domain + "/entites-externes", {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
        params,
      });

      

      if (response && response.data && Array.isArray(response.data.data)) {
        commit("UPDATE_ENTITE_EXTERNE_ROW", response.data.data.length);
        commit("SET_ENTITES_EXTERNES", response.data.data); 
      } else {
     
        commit("SET_ENTITES_EXTERNES", []);
      }

      commit("SET_LOADING_ENTITE_EXTERNE", false);
      return true;
    } catch (error) {
      commit("SET_LOADING_ENTITE_EXTERNE", false);
     
      throw error.response ? error.response.data.message : error.message;
    }
  },
  async entiteExterne({ commit }, payload) {
    commit("DO_NOTHING");
    try {
      const response = await axios.get(
        domain + `/entites-externes/` + payload,
        {
          headers: {
            Authorization: `Bearer ` + this.state.token,
          },
        }
      );
      commit("SET_ENTITE_EXTERNE", response.data.data);
      return response.data;
    } catch (error) {
      throw error.response.data.message;
    }
  },
  async storeEntiteExterne({ commit }, payload) {
    commit("SET_LOADING_ENTITE_EXTERNE", true);
    try {
      const response = await axios.post(
        domain + `/entites-externes`,
        payload,
        {
          headers: {
            Authorization: `Bearer ` + this.state.token,
          },
        }
      );

      commit("PUSH_ENTITE_EXTERNE", response.data.data);
      commit("UPDATE_ENTITE_EXTERNE_ROW", state.entiteExterneRows + 1);
      commit("SET_LOADING_ENTITE_EXTERNE", false);
      return true;
    } catch (error) {
      commit("SET_LOADING_ENTITE_EXTERNE", false);
      throw error.response.data.message;
    }
  },
  async deleteEntiteExterne({ commit }, payload) {
    commit("SET_LOADING_ENTITE_EXTERNE", true);
    try {
      await axios.delete(domain + `/entites-externes/` + payload.id, {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
      });
      commit("DELETE_ENTITE_EXTERNE", payload);
      commit("UPDATE_ENTITE_EXTERNE_ROW", state.entiteExterneRows - 1);
      commit("SET_LOADING_ENTITE_EXTERNE", false);
      return true;
    } catch (error) {
      commit("SET_LOADING_ENTITE_EXTERNE", false);
      throw error.response.data.message;
    }
  },
};

export default { state, mutations, getters, actions };
