import domain from "@/environment";
import axios from "axios";

const state = {
  logement: null,
  logements: [],
  logementRows: 0,
  logementLoading: false,
};

const getters = {
  getLogement: (state) => state.logement,
  getAllLogements: (state) => state.logements,
  getTotalRowLogements: (state) => state.logementRows,
  getLoadingLogement: (state) => state.logementLoading,
};

const mutations = {
  DO_NOTHING() {},
  SET_LOADINGLOGEMENT(state, payload) {
    state.logementLoading = payload || false;
  },
  SET_LOGEMENT(state, payload) {
    state.logement = payload || null;
  },
  PUSH_LOGEMENT(state, payload) {
    state.logements.push(payload);
  },
  UPDATE_LOGEMENT(state, payload) {
    const index = state.logements.findIndex(l => l.id == payload.id);
    if (index !== -1) {
      state.logements[index] = { ...state.logements[index], ...payload };
    }
  },
  UPDATE_LOGEMENT_ROW(state, payload) {
    state.logementRows = payload || 0;
  },
  DELETE_LOGEMENT(state, payload) {
    state.logements = state.logements.filter(l => l.id != payload.id);
  },
  SET_LOGEMENTS(state, payload) {
    state.logements = payload || [];
  },
};

const actions = {
  async allLogements({ commit }, payload) {
    const params = {
      page: payload.page,
      per_page: payload.per_page,
      search: payload.search,
    };
    try {
      const response = await axios.get(domain + `/logements`, {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
        params,
      });
      commit("SET_LOGEMENTS", response.data.data.data);
      commit("UPDATE_LOGEMENT_ROW", response.data.data.total);
      return response.data.data;
    } catch (error) {
      return error.response.data.message;
    }
  },
  async getLogement({ commit }, payload) {
    try {
      const response = await axios.get(domain + `/logements/` + payload, {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
      });
      commit("SET_LOGEMENT", response.data.data);
      return response.data;
    } catch (error) {
      return error.response.data.message;
    }
  },
  async storeLogement({ commit }, payload) {
    commit("SET_LOADINGLOGEMENT", true);
    try {
      const response = await axios.post(domain + `/logements`, payload, {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
      });
      commit("PUSH_LOGEMENT", response.data.data);
      commit("SET_LOADINGLOGEMENT", false);
      return response.data;
    } catch (error) {
      commit("SET_LOADINGLOGEMENT", false);
      return error.response.data.message;
    }
  },
  async editLogement({ commit }, payload) {
    commit("SET_LOADINGLOGEMENT", true);
    try {
      const response = await axios.put(
        domain + `/logements/` + payload.id,
        payload,
        {
          headers: {
            Authorization: `Bearer ` + this.state.token,
          },
        }
      );
      commit("UPDATE_LOGEMENT", response.data.data);
      commit("SET_LOADINGLOGEMENT", false);
      return response.data.data;
    } catch (error) {
      commit("SET_LOADINGLOGEMENT", false);
      return error.response.data.message;
    }
  },
  async deleteLogement({ commit }, payload) {
    commit("SET_LOADINGLOGEMENT", true);
    try {
      await axios.delete(domain + `/logements/` + payload.id, {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
      });
      commit("DELETE_LOGEMENT", payload);
      commit("SET_LOADINGLOGEMENT", false);
      return true;
    } catch (error) {
      commit("SET_LOADINGLOGEMENT", false);
      return error.response.data.message;
    }
  },
};

export default { state, mutations, getters, actions };
