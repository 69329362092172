import domain from "@/environment";
import axios from "axios";

const state = {
  ChauffesEau: [],
  ChauffeEau: null,
  ChauffeEauRows: 0,
  ChauffeEauLoading: false,
};

const getters = {
  getChauffeEau: (state) => state.ChauffeEau,
  getAllChauffeEau: (state) => state.ChauffesEau,
  getTotalRowChauffeEau: (state) => state.ChauffeEauRows,
  getLoadingChauffeEau: (state) => state.ChauffeEauLoading,
};

const mutations = {
  DO_NOTHING() {},
  SET_LOADING_Chauffe_Eau(state, payload) {
    state.ChauffeEauLoading = payload || false;
  },
  SET_Chauffe_Eau(state, payload) {
    state.ChauffeEau = payload || null;
  },
  PUSH_Chauffe_Eau(state, payload) {
    state.ChauffesEau.push(payload);
  },
  UPDATE_Chauffe_Eau(state, payload) {
    const index = state.ChauffesEau.findIndex(
      (sc) => sc.id === payload.id
    );
    if (index !== -1) {
      state.ChauffesEau[index] = {
        ...state.ChauffesEau[index],
        ...payload,
      };
    }
  },
  UPDATE_Chauffe_Eau_ROW(state, payload) {
    state.ChauffeEauRows = payload || 0;
  },
  DELETE_Chauffe_Eau(state, payload) {
    state.ChauffesEau = state.ChauffesEau.filter(
      (sc) => sc.id !== payload.id
    );
  },
  SET_Chauffe_EauS(state, payload) {
    state.ChauffesEau = payload || [];
  },
};

const actions = {
  async allChauffeEau({ commit }, payload) {
    const params = {
      page: payload.page,
      per_page: payload.per_page,
      search: payload.search,
    };
    try {
      const response = await axios.get(domain + `/chauffe-eau`, {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
        params,
      });
      commit("SET_Chauffe_EauS", response.data.data.data);
      commit("UPDATE_Chauffe_Eau_ROW", response.data.data.total);
      return response.data.data;
    } catch (error) {
      return error.response.data.message;
    }
  },
  async getChauffeEau({ commit }, payload) {
    try {
      const response = await axios.get(
        domain + `/chauffe-eau/` + payload,
        {
          headers: {
            Authorization: `Bearer ` + this.state.token,
          },
        }
      );
      commit("SET_Chauffe_Eau", response.data.data);
      return response.data;
    } catch (error) {
      return error.response.data.message;
    }
  },
async storeChauffeEau({ commit }, payload) {
  commit("SET_LOADING_Chauffe_Eau", true);
  try {
    const response = await axios.post(domain + `/chauffe-eau`, payload, {
      headers: {
        Authorization: `Bearer ` + this.state.token,
      },
    });
    commit("PUSH_Chauffe_Eau", response.data.data);
    commit("SET_LOADING_Chauffe_Eau", false);
    return response.data;
  } catch (error) {
    commit("SET_LOADING_Chauffe_Eau", false);
    throw error; 
  }
},
async editChauffeEau({ commit }, payload) {
  commit("SET_LOADING_Chauffe_Eau", true);
  try {
   
    const response = await axios.put(
      domain + `/chauffe-eau/` + payload.id,
      payload,
      {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
      }
    );

    if (response && response.data && response.data.data) {
      commit("UPDATE_Chauffe_Eau", response.data.data);
    } else {
     
      throw new Error("Unexpected response structure");
    }

    commit("SET_LOADING_Chauffe_Eau", false);
    return response.data.data;
  } catch (error) {
    commit("SET_LOADING_Chauffe_Eau", false);


  
    return error.response?.data?.message || "An error occurred";
  }
},
  async deleteChauffeEau({ commit }, payload) {
    commit("SET_LOADING_Chauffe_Eau", true);
    try {
      await axios.delete(domain + `/chauffe-eau/` + payload.id, {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
      });
      commit("DELETE_Chauffe_Eau", payload);
      commit("SET_LOADING_Chauffe_Eau", false);
      return true;
    } catch (error) {
      commit("SET_LOADING_Chauffe_Eau", false);
      return error.response.data.message;
    }
  },
};

export default { state, mutations, getters, actions };
